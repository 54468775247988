<template>
  <div class="carousel-cards">
    <carousel :items-to-show="valor" style="width: 100%">
      <slide key="slide">
        <div
          class="carousel-card"
          style="
            border-width: thick;
            border-bottom-style: solid;
            border-bottom-color: #00b7bb;
            box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.16);
          "
        >
          <img src="./../images/card-icon1.svg" alt />
          <p style="color: #00b7bb">{{ $t("questions.cards.card1") }}</p>
          <i></i>
        </div>
      </slide>
      <slide key="slide">
        <div
          class="carousel-card"
          style="
            border-width: thick;
            border-bottom-style: solid;
            border-bottom-color: #ed9f40;
            background-color: #f5f5f5;
          "
        >
          <img src="./../images/card-icon2.svg" alt />
          <p>{{ $t("questions.cards.card2") }}</p>
          <i class="material-icons" style="color: #ed9f40">lock</i>
        </div>
      </slide>
      <slide key="slide">
        <div
          class="carousel-card"
          style="
            border-width: thick;
            border-bottom-style: solid;
            border-bottom-color: #ed9f40;
            background-color: #f5f5f5;
          "
        >
          <img src="./../images/card-icon3.svg" alt />
          <p>{{ $t("questions.cards.card3") }}</p>
          <i class="material-icons" style="color: #ed9f40">lock</i>
        </div>
      </slide>
      <slide key="slide">
        <div
          class="carousel-card"
          style="
            border-width: thick;
            border-bottom-style: solid;
            border-bottom-color: #533a8b;
            background-color: #f5f5f5;
          "
        >
          <img src="./../images/card-icon4.svg" alt />
          <p>{{ $t("questions.cards.card4") }}</p>
          <i class="material-icons" style="color: #533a8b">lock</i>
        </div>
      </slide>
      <slide key="slide">
        <div
          class="carousel-card"
          style="
            border-width: thick;
            border-bottom-style: solid;
            border-bottom-color: #533a8b;
            background-color: #f5f5f5;
          "
        >
          <img src="./../images/card-icon5.svg" alt />
          <p>{{ $t("questions.cards.card5") }}</p>
          <i class="material-icons" style="color: #533a8b">lock</i>
        </div>
      </slide>
      <slide key="slide">
        <div
          class="carousel-card"
          style="
            border-width: thick;
            border-bottom-style: solid;
            border-bottom-color: #533a8b;
            background-color: #f5f5f5;
          "
        >
          <img src="./../images/card-icon6.svg" alt />
          <p>{{ $t("questions.cards.card6") }}</p>
          <i class="material-icons" style="color: #533a8b">lock</i>
        </div>
      </slide>
    </carousel>
  </div>
</template>
<script>
import { Carousel, Navigation, Pagination, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
export default {
  name: "CarouselCards",
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  data() {
    return {
      valor: 4.5,
    };
  },
};
</script>

<style lang="scss">
ol > li {
  min-width: 230px !important;
  min-height: 80px !important;
  margin: 1rem !important;
}

.carousel-cards {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 2vh 0vw 2vh 1vw;
}

.carousel-card {
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  min-height: 80px;
  max-height: 80px;
  margin: 0 1rem;
  padding: 3rem 1rem;
  width: 25vw;
  min-width: 230px !important;
  p {
    color: #687d9b;
    font-family: Nunito-Regular;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 110%;
    padding: 0vh 0.5vw;
  }
  img {
    width: 4vw;
    min-width: 50px;
  }
}
</style>